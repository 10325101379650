import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom'
import { trabajos } from '../data/trabajos'

export const Proyecto = () => {

    const [proyecto, setProyecto] = useState ({})
    const params= useParams();

    useEffect(()=> {
        let proyecto = trabajos.filter( trabajo => trabajo.id === params.id)
        setProyecto(proyecto[0]);
    }, []);
    

  return (
    <div className= 'page page-work'>
      <h1 className='heading'>{proyecto.nombre}</h1>
      <p>{proyecto.tecnologias}</p>  
      <p>{proyecto.descripcion}</p> 
      <a className='button' href={"http://"+proyecto.url} target="_blank">Ir al proyecto</a>
      <div className='mask'>
        <a href={"http://"+proyecto.url} target="_blank"><img src={'/images/'+proyecto.id+'.png'} alt='' width='100%'/></a>
      </div>
    </div>
  )
}
