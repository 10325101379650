import React from 'react'

export const Servicios = () => {
  return (
    <div className= 'page'>
      <h1 className='heading'>Servicios</h1>
      <section className='services'>

        <article className='service'>
          <h2>Diseño web FullStack</h2>
          <p>Para dispositivos de escritos, diseños responsivos con React JS, PHP, Javascript, y bases de datos SQL.</p>
        </article>

        <article className='service'>
          <h2>Desarrollo de aplicaciones móviles</h2>
          <p>El mundoestá en constante evolución por ello, centro los diseños en el usuario.</p>
        </article>
      </section>
    </div>
  )
}
