import React from 'react'

export const Curriculum = () => {
  return (
    <div className= 'page'>
      <h1 className='heading'>Full Stack Web Developer</h1>
      <h3>SQL | Express | React | Node | Redux</h3>
        <ul>
          <li></li>
          <li>Experiencia SQL, Express JS, React JS, Node JS, NextJS, Redux, Javascript ES6, Typescript, React Bootstrap, jQuery, PostgreSQL, PHP, MySQL, HTML5, CSS3, Bootstrap, Flexbox, Sass, Xampp, Workbench, Git, GitHub.</li>
        </ul>
    </div>
  )
}
