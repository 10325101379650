export const trabajos = [
    {
        'id':'criptos',
        'nombre':'Cotizador de Criptos',
        'url':'criptos.juanromero.ar',
        'tecnologias':'React JS, API',
        'categorias':'Desarrollo',
        'descripcion':'proyecto desarrollado por JR'
    },
    {
        'id':'portfolio-cara',
        'nombre':'Just another portfolio React JS ',
        'url':'juanromero.ar',
        'tecnologias':'React JS, MySQL',
        'categorias':'Desarrollo',
        'descripcion':'proyecto desarrollado por JR'
    },
    {
        'id':'silicon',
        'nombre':'Silicon Project',
        'url':'silicon.juanromero.ar',
        'tecnologias':'React JS, Node, Express, MySQL',
        'categorias':'Desarrollo',
        'descripcion':'proyecto desarrollado por JR'
    },
    {
        'id':'minegocio',
        'nombre':'Negocio React JS ',
        'url':'misdev.juanromero.ar',
        'tecnologias':'React JS, MySQL',
        'categorias':'Desarrollo',
        'descripcion':'proyecto desarrollado por JR'
    },
    {
        'id':'peliculas',
        'nombre':'Listado Películas',
        'url':'peliculas.juanromero.ar',
        'tecnologias':'React JS, Hooks, LocalStorage',
        'categorias':'Desarrollo',
        'descripcion':'proyecto desarrollado por JR'
    },
    {
        'id':'contact',
        'nombre':'Listado Contacto',
        'url':'contact.juanromero.ar',
        'tecnologias':'Vite, LocalStorage',
        'categorias':'Desarrollo',
        'descripcion':'proyecto desarrollado por JR'
    },
    {
        'id':'torneito',
        'nombre':'Torneo de Tenis',
        'url':'torneito.com.ar',
        'tecnologias':'HTML, Css, JS, PHP, MySQL',
        'categorias':'Desarrollo',
        'descripcion':'proyecto desarrollado por JR'
    },
    {
        'id':'php_avanzado',
        'nombre':'Proyecto PHP Avanzado',
        'url':'portafolio.ar/php_avanzado_utn/',
        'tecnologias':'HTML, Css, PHP, MySQL',
        'categorias':'Desarrollo',
        'descripcion':'proyecto desarrollado por JR'
    },
    {
        'id':'php_intermedio',
        'nombre':'Proyecto PHP Intermedio',
        'url':'portafolio.ar/php_intermedio_utn/noticias.php',
        'tecnologias':'HTML, Css, PHP, MySQL',
        'categorias':'Desarrollo ',
        'descripcion':'proyecto desarrollado por JR'
    },
    {
        'id':'javascript_utn',
        'nombre':'Proyecto Javascript UTN',
        'url':'portafolio.ar/javascript_utn/',
        'tecnologias':'HTML, Css, Javascript',
        'categorias':'Desarrollo',
        'descripcion':'proyecto desarrollado por JR'
    },
    {
        'id':'portafolio_intro',
        'nombre':'Introducción Programación',
        'url':'portafolio.ar/intro_siliconMisiones/',
        'tecnologias':'HTML, Css, Javascript',
        'categorias':'Desarrollo',
        'descripcion':'proyecto desarrollado por JR'
    },

];