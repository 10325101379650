import React from 'react'
import { Link } from 'react-router-dom'
import { ListadoTrabajos } from './ListadoTrabajos'

export const Inicio = () => {
  return (
    <div className='home'>
      <h1>
        Hola, mi nombre es  <strong>Juan Romero</strong>
      </h1>
      <br/>
      <h2 className='title'> 
        Soy desarrollador web FullStack SERN (SQL, Express, React, Node)<br/><br/><Link to="/portafolio">portafolio</Link>
      </h2>
      <section className='lasts-works'>
        <h2 className='heading'>Algunos proyectos</h2><br/><Link to="/contacto"> Contacto</Link> 
      <ListadoTrabajos limite='3' />
      </section>
    </div>

  )
}
