import React from 'react'

export const Contacto = () => {
  return (
    <div className= 'contacto'>
      <div>
        <h1 className='heading'>Redes sociales</h1>
        <p><a href="https://www.linkedin.com/in/romeroresek/" target="_blank">Linkedin</a></p>
      </div>
      <div>
        <h1 className='heading'>Contacto</h1>
        <form className='contact' action="https://formsubmit.io/send/juanromero1@hotmail.com" method="POST">
          <input name="name" type="text" id="name" placeholder='Nombre'/>
          <input name="email" type="email" id="email" placeholder='Email'/>
          <textarea name="comment" id="comment" rows="3" placeholder='Comentario'></textarea>
          <input value="Submit" type="submit"/>
        </form>
      </div>
    </div>
  )
}
