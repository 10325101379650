import React from 'react'
import { Link } from 'react-router-dom';
import {trabajos} from '../data/trabajos';

export const ListadoTrabajos = ({limite}) => {
  return (
      <section className='works'>
        {
          trabajos.slice(0,limite).map(trabajo => {
            return (
              <article key={trabajo.id} className='work-item'>
                <span>{trabajo.categorias}</span>
                <h2><Link to={'/proyecto/'+trabajo.id}>{trabajo.nombre}</Link></h2>
                <h3>{trabajo.tecnologias}</h3>
                
                <div className='mask'>
                <Link to={'/proyecto/'+trabajo.id}><img src={'/images/'+trabajo.id+'.png'} alt=''/></Link>
                </div>
 
              </article>
            );
          })
        }
      </section>
  )
}